<template>
  <div class="detail-modal-container">
    <a-modal
      :visible="modalVisible"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      okText="提交"
      :maskClosable="false"
      :width="1139"
    >
      <div style="margin-left: 3rem">调整</div>
      <a-divider />
      <a-form-model>
        <a-form-model-item label="显示方式">
          <a-radio-group v-model="submitFrom.strategy" @change="handleData">
            <a-radio :value="0"> 真实数据 </a-radio>
            <a-radio :value="1"> 调整数据 </a-radio>
            <a-radio :value="2"> 随机调整 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="调整基数" v-if="baseVisible">
          <a-input-number
            placeholder="请输入"
            style="width: 28rem"
            :min="0"
            :precision="0"
            v-model="submitFrom.baseNum"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="随机调整基数" v-if="randomVisible">
          <a-input-number
            placeholder="最小值"
            style="width: 10rem; margin-right: 4rem"
            :min="0"
            :precision="0"
            v-model="submitFrom.minNum"
          ></a-input-number>
          <a-input-number
            placeholder="最大值"
            style="width: 10rem"
            :min="submitFrom.minNum + 1"
            :precision="0"
            v-model="submitFrom.maxNum"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/selectAnasysis";
import { tips } from "@/utils/tips";

export default {
  mixins: [mixins],
  data() {
    return {
      submitFrom: {
        strategy: 1, // 策略（默认为调整数据）
        baseNum: null, // 调整基数
        minNum: null, // 最小随机调整基数
        maxNum: null, // 最大随机调整基数
      },
      baseVisible: true, // 调整基数输入框显示
      randomVisible: false, // 随机调整输入框显示
    };
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "1",
    },
    config: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleOk() {
      if (this.submitFrom.strategy === 1 && this.submitFrom.baseNum === null) {
        this.$message.error("调整基数为必填");
        return;
      } else {
        let resultId = this.config.map((item) => {
          return item.goodsId;
        });
        let adjustmentNumDTO = {
          action: this.action,
          ...this.submitFrom,
          goodsIdList: resultId,
        };
        let self = this;
        this.$confirm({
          title: "是否确定提交数据?",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.adjustmentBase(adjustmentNumDTO).then((res) => {
              tips(res, "调整");
              if (res.success) {
                self.cancel();
                self.$emit("deleteChecked");
                self.$parent.getSourceData("调整");
              }
              self.loading = false;
            });
          },
        });
      }
    },
    handleData() {
      if (this.submitFrom.strategy === 0) {
        this.baseVisible = false;
        this.randomVisible = false;
      } else if (this.submitFrom.strategy === 1) {
        this.baseVisible = true;
        this.randomVisible = false;
      } else {
        this.baseVisible = false;
        this.randomVisible = true;
      }
    },
  },
  created() {
    if (this.config.length > 1) {
      this.submitFrom.baseNum = 0;
    } else {
      this.submitFrom.baseNum = this.config[0].adjustmentNum;
    }
  },
};
</script>

<style lang="scss" scoped>
.ant-form {
  padding-left: 6rem;
  padding-bottom: 30rem;
}
.ant-row {
  display: flex;
}
.ant-input {
  width: 28.5rem;
}
</style>
