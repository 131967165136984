import axios from "@/config/http.js";

export const API = {
  // 分页查询阅览-询盘次数或者成交量总量
  async pageIndex(params) {
    return await axios.get("/analysis/goods/action", { params });
  },
  // 调整基数
  async adjustmentBase(params) {
    return await axios.patch("/analysis/goods/adjust", params);
  },
};
