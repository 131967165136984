<template>
  <div class="selection-analysis-container container-index">
    <div class="container-index container-card">
      <a-button type="primary" @click="openModal">调整</a-button>
      <a-tabs
        default-active-key="1"
        @change="handleChangeTab"
        destroyInactiveTabPane
      >
        <a-tab-pane key="1" tab="阅览次数">
          <div class="tab-layout" v-if="cardVisible">
            <GoodsCard
              v-for="(item, i) in cardList"
              :config="item"
              :key="i"
              :goodsType="'阅览'"
              @cardChecked="handleCardChecked"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="询盘次数">
          <div class="tab-layout" v-if="cardVisible">
            <GoodsCard
              v-for="(item, i) in cardList"
              :config="item"
              :key="i"
              :goodsType="'询盘'"
              @cardChecked="handleCardChecked"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="成交总量">
          <div class="tab-layout" v-if="cardVisible">
            <GoodsCard
              v-for="(item, i) in cardList"
              :config="item"
              :key="i"
              :goodsType="'成交'"
              @cardChecked="handleCardChecked"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="mt2 pagination">
      <a-pagination
        :total="total"
        :current="current"
        :pageSize="pageSize"
        showSizeChanger
        @change="(current) => onPage(current)"
        @showSizeChange="(current, size) => onSizeChange(current, size)"
      />
    </div>

    <!-- 调整 -->
    <DetailModal
      :modalVisible="modalVisible"
      v-if="modalVisible"
      :config="configFrom"
      :action="action"
      @deleteChecked="handleDeleteChecked"
    />
  </div>
</template>

<script>
import GoodsCard from "@/components/GoodsCard.vue";
import DetailModal from "./detailModal.vue";
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/selectAnasysis";

export default {
  mixins: [mixins],
  components: {
    GoodsCard,
    DetailModal,
  },
  data() {
    return {
      modalVisible: false,
      checkedList: [],
      action: "1", // 询盘，阅览，成交
      cardList: [],
      total: 0,
      configFrom: [],
      cardVisible: true, // 为了解决关闭调整弹框，取消卡片的选中效果
      pageSize: 20,
      current: 1,
    };
  },
  methods: {
    // 调整
    openModal() {
      if (this.checkedList.length < 1) {
        this.$message.error("请选择一个产品");
        return;
      }
      this.modalVisible = true;
      this.configFrom = this.checkedList;
    },
    closeModal() {
      this.modalVisible = false;
    },
    handleChangeTab(val) {
      this.action = val;
      this.checkedList = [];
      this.current = 1;
      this.getSourceData();
    },
    onChange() {},
    // 卡片是否选中切换
    handleCardChecked(val) {
      // 判断目前卡片数组中有没有选中信息，有就移除，没有则添加
      let index = this.checkedList.findIndex((item) => {
        return item.goodsId === val.goodsId;
      });
      if (index > -1) {
        // 移除当前选中卡片
        this.checkedList.splice(index, 1);
      } else {
        this.checkedList.push(val);
      }
    },
    // 获取选品分析首页数据
    getSourceData(type) {
      this.cardVisible = false;
      if (type === "调整") {
        console.log("要把页数变成第一页");
      }

      let detail = {
        action: this.action,
        page: this.current,
        size: this.pageSize,
      };
      API.pageIndex(detail).then((res) => {
        if (res.success) {
          this.cardVisible = true;
          this.total = res.data.total;
          this.cardList = res.data.records;
        }
      });
    },
    // 处理关闭调整弹框，清空选中卡片的数组
    handleDeleteChecked() {
      this.checkedList = [];
    },
    // 分页
    onPage(current) {
      this.current = current;
      this.getSourceData();
    },
    onSizeChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getSourceData();
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.selection-analysis-container {
  height: 100%;
  .container-card {
    height: 93%;
    overflow: hidden;
  }

  .tab-layout {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    // overflow: auto;
  }
  .pagination {
    position: absolute;
    right: 6rem;
  }
}
/deep/ .ant-card-bordered {
  margin-right: 1.5rem;
}

/deep/ .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
  height: 900px;
  padding-bottom: 40rem;
  overflow-y: scroll;
}
</style>
