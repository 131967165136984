<template>
  <div class="goods-card-container">
    <a-checkbox @change="handleChange">
      <div :class="{ 'card-bg': cardBgVisible }"></div>
      <a-card hoverable style="width: 368px">
        <img
          slot="cover"
          alt="example"
          class="imgGoods"
          :src="config.spuImage"
        />
        <template slot="actions" class="ant-card-actions">
          <div>
            <p :class="{ 'p-active': realDealColor }">{{ config.num }}</p>
            <p>{{ `实际${goodsType}总量` }}</p>
          </div>
          <div>
            <p :class="{ 'p-active': adjustmentColor }">
              {{ config.adjustmentNum }}
            </p>
            <p>{{ `调整${goodsType}总量` }}</p>
          </div>
        </template>
        <a-card-meta :title="config.goodsNameCn">
          <template slot="description">
            <div class="desc">
              <span>基础价:</span
              ><span class="price">¥{{ config.onPrice }}</span>
            </div>
            <div class="desc">
              <span>起订量:</span
              ><span style="margin-left: 5px">{{ config.minOrder }}</span>
            </div>
          </template>
        </a-card-meta>
      </a-card>
    </a-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardBgVisible: false, // 遮罩层显示
      realDealColor: false, // 真实成交量颜色控制
      adjustmentColor: false, // 调整成交量颜色控制
    };
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    goodsType: {
      type: String,
      default: "成交",
    },
  },
  methods: {
    handleChange(val) {
      if (val.target.checked) {
        this.cardBgVisible = true;
      } else {
        this.cardBgVisible = false;
      }
      this.config.cardBgVisible = this.cardBgVisible;
      this.$emit("cardChecked", this.config);
    },
  },
  created() {
    if (this.config.strategy) {
      this.realDealColor = true;
    } else {
      this.adjustmentColor = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.goods-card-container {
  position: relative;
  .desc {
    height: 2rem;
    line-height: 2rem;
    .price {
      color: #e41e1e;
      font-size: 2rem;
      margin-left: 5px;
      font-weight: 500;
    }
  }
}
.card-bg {
  width: 368px;
  height: 405px;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.imgGoods {
  height: 22rem;
  width: 100%;
  border-bottom: 1px solid #e0dddd;
  border-radius: 3px;
  object-fit: cover;
}

p {
  margin-bottom: 0rem;
  color: #999;
  font-size: 1.4rem;
}
.p-active {
  color: #ff4600;
}
/deep/ .ant-card-meta-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-checkbox {
  z-index: 100;
  top: 3rem;
  left: 1rem;
}

</style>
